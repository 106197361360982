import { graphql } from 'gatsby'

export const headerFields = graphql`
  fragment headerSectionFields on WordPressAcf_header {
    id
    title
    text
    images {
      image_sm {
        ...imageFields
      }
      image_md {
        ...imageFields
      }
      image_lg {
        ...imageFields
      }
      image_xl {
        ...imageFields
      }
    }
    background_color
    action_button {
      button_title
      button_title_xs
      type
      page_link {
        post_name
      }
    }
  }
`

export const pageHeadsectionFields = graphql`
  fragment pageHeadsectionFields on wordpress__PAGEAcfHeader {
    title
    text
    images {
      image_sm {
        ...imageFields
      }
      image_md {
        ...imageFields
      }
      image_lg {
        ...imageFields
      }
      image_xl {
        ...imageFields
      }
    }
    background_color
    action_button {
      button_title
      button_title_xs
      type
      page_link {
        post_name
      }
    }
  }
`

export const mortgageCalculatorFields = graphql`
  fragment mortgageCalculatorSectionFields on WordPressAcf_mortgage_calculator {
    id
    strapline
    text
    text_xs
    action_button {
      button_title
      button_title_xs
      type
      page_link {
        post_name
      }
    }
  }
`

export const threeTextFields = graphql`
  fragment threeTextSectionFields on WordPressAcf_three_texts {
    id
    strapline
    title
    title_xs
    text
    text_xs
    texts {
      title
      text
      text_xs
    }
    images {
      image_sm {
        ...imageFields
      }
      image_md {
        ...imageFields
      }
      image_lg {
        ...imageFields
      }
      image_xl {
        ...imageFields
      }
    }
  }
`

export const threeIconTextsFields = graphql`
  fragment threeIconTextsSectionFields on WordPressAcf_three_icon_texts {
    id
    texts {
      title
      text
      text_xs
    }
  }
`

export const pictureButtonFields = graphql`
  fragment pictureButtonSectionFields on WordPressAcf_picture_button {
    id
    title
    images {
      image_sm {
        ...imageFields
      }
      image_md {
        ...imageFields
      }
      image_lg {
        ...imageFields
      }
      image_xl {
        ...imageFields
      }
    }
    background_color
    action_button {
      button_title
      button_title_xs
      type
      page_link {
        post_name
      }
    }
  }
`

export const floatLeftRight = graphql`
  fragment floatLeftRightSectionFields on WordPressAcf_float_left_right {
    id
    strapline
    title
    title_xs
    text
    float_text_left
    images {
      image_sm {
        ...imageFields
      }
      image_md {
        ...imageFields
      }
      image_lg {
        ...imageFields
      }
      image_xl {
        ...imageFields
      }
    }
  }
`

export const letter = graphql`
  fragment letterSectionFields on WordPressAcf_letter {
    id
    title
    text
    text_extended
    job_title
    name
    background_color
    signature
  }
`

export const testimonial = graphql`
  fragment testimonialSectionFields on WordPressAcf_testimonial {
    id
    testimonials {
      image {
        localFile {
          childImageSharp {
            large: fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      link
    }
  }
`

export const pageFields = graphql`
  fragment pageFields on Node {
    __typename
    ... on WordPressAcf_header {
      ...headerSectionFields
    }
    ... on WordPressAcf_mortgage_calculator {
      ...mortgageCalculatorSectionFields
    }
    ... on WordPressAcf_three_texts {
      ...threeTextSectionFields
    }
    ... on WordPressAcf_three_icon_texts {
      ...threeIconTextsSectionFields
    }
    ... on WordPressAcf_picture_button {
      ...pictureButtonSectionFields
    }
    ... on WordPressAcf_float_left_right {
      ...floatLeftRightSectionFields
    }
    ... on WordPressAcf_letter {
      ...letterSectionFields
    }
    ... on WordPressAcf_testimonial {
      ...testimonialSectionFields
    }
  }
`
