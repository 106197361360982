import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Container, AnimatedBounce, Input } from '~components'
import { tx } from '~libs/i18n'
import { ApplicationQueueStore } from 'stores'
import styles from '../../modal.module.scss'

export interface InvitedScreenProps {
  applicationQueueStore?: ApplicationQueueStore
  visible: boolean
  onHidden: () => void
}

@inject('applicationQueueStore')
@observer
export class InvitedScreen extends Component<InvitedScreenProps> {
  state = { mounted: false }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 1)
  }

  onBlur = item => {
    const { applicationQueueStore } = this.props
    applicationQueueStore.validateItem(item, true)
  }
  onEnter = item => {
    const { applicationQueueStore } = this.props
    if (
      applicationQueueStore.validateItem(item, true) &&
      applicationQueueStore.statuses.requestQueue !== 'pending'
    ) {
      applicationQueueStore.requestQueue()
    }
  }

  render() {
    const { applicationQueueStore, visible, onHidden } = this.props
    const invitationCodeField = applicationQueueStore.fields.invitationCode
    return (
      <Container
        className={[styles.container, styles.queue_wrapper, 'flex_center_col'].join(' ')}
        application
      >
        <AnimatedBounce visible={visible && this.state.mounted} onHidden={onHidden}>
          <span className={styles.strapline}>
            {tx('application_queue.invited_screen.strapline')}
          </span>
          <h1>{tx('application_queue.invited_screen.title')}</h1>
          <p className="text">{tx('application_queue.invited_screen.text')}</p>

          <Input
            {...invitationCodeField}
            onChange={value => (invitationCodeField.value = value)}
            onBlur={() => this.onBlur(invitationCodeField)}
            onEnter={() => this.onEnter(invitationCodeField)}
          />

          <Button
            className={styles.intro_button}
            onClick={applicationQueueStore.validateInvitationCode}
            disabled={
              !applicationQueueStore.validateItem(invitationCodeField, false) &&
              applicationQueueStore.statuses.requestQueue !== 'pending'
            }
          >
            {tx('misc.apply')}
          </Button>
        </AnimatedBounce>
      </Container>
    )
  }
}
