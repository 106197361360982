import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore, typeApplicants, RfqStore } from 'stores'
import { Container, Button } from '~components'
import { tx } from '~libs/i18n'
import { BankId } from './BankId'
import { OverviewPartial } from './OverviewPartial'
import styles from './styles.module.scss'
import { InterestBar } from '../../components'

export interface OverviewScreenProps {
  applicationUIStore?: ApplicationUIStore
  rfqStore?: RfqStore
  startIndex: number
  visible: boolean
  onHidden: () => void
}

const KEYS = [
  'applicant.applicant',
  'applicant.employment',
  'applicant.collateral',
  'applicant.debt',
]
const KEYS_WITH_COAPPLICANT = [
  'applicant.applicant',
  'applicant.employment',
  'coapplicant.applicant',
  'coapplicant.employment',
  'applicant.collateral',
  'applicant.debt',
  'coapplicant.debt',
]

@inject('applicationUIStore', 'rfqStore')
@observer
export class OverviewScreen extends Component<OverviewScreenProps> {
  containerRef = React.createRef<Container>()

  componentDidMount() {
    const container = findDOMNode(this.containerRef.current)
    container && (container.parentElement.scrollTop = 0)
  }

  componentDidUpdate(lastProps) {
    if (!this.props.visible && this.props.visible !== lastProps.visible) {
      this.props.onHidden()
    }
  }

  render() {
    const { applicationUIStore, rfqStore, startIndex } = this.props

    const keys = applicationUIStore.hasCoapplicant() ? KEYS_WITH_COAPPLICANT : KEYS

    let partials = keys.map((key, index) => {
      const [type, storeInfoKey] = key.split('.')

      return (
        <OverviewPartial
          key={key}
          editIndex={index + startIndex}
          storeInfoKey={storeInfoKey}
          type={type as typeApplicants}
        />
      )
    })

    // Flatten
    partials = [].concat(...partials)

    const showBackButton =
      ['idle', 'error'].includes(rfqStore.state['applicant']) &&
      ['idle', 'error'].includes(rfqStore.state['coapplicant'])

    return (
      <>
        <Container
          ref={this.containerRef}
          className={[styles.container, styles.overview_screen].join(' ')}
          application
        >
          <p className={styles.strapline}>{tx('application.overview.strapline')}</p>
          <h2 className="text-center">{tx('application.overview.title')}</h2>

          {partials}

          <BankId />

          {showBackButton && (
            <Button
              onClick={applicationUIStore.previousScreen}
              styleKey="button_application_back"
              className={styles.back_button}
            >
              {tx('misc.back')}
            </Button>
          )}
        </Container>
        <InterestBar />
      </>
    )
  }
}
