import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore, typeApplicants } from 'stores'
import { IInputItem, IInputState } from '~stores/ApplicationUIStore/props'
import { Input, Button, AnimatedHeight } from '~components'
import styles from './styles.module.scss'

export interface ResultInputArrayProps {
  applicationUIStore?: ApplicationUIStore
  arrayInput: IInputItem
  type: typeApplicants
  onValidateNext: () => void
}

@inject('applicationUIStore')
@observer
export class ResultInputArray extends Component<ResultInputArrayProps> {
  state = {
    pendingDeletion: {},
  }

  static key = 0

  generateKey = () => {
    ResultInputArray.key++
    return ResultInputArray.key.toString()
  }

  onAddRow = value => {
    const { applicationUIStore, arrayInput, type } = this.props
    const { arrayTriggerKey, arrayTemplate } = this.props.arrayInput

    const copy = toJS(arrayTemplate)
    copy[Object.keys(copy)[0]].key = this.generateKey()
    const newRow = arrayInput.value[arrayInput.value.push(copy) - 1]
    applicationUIStore.set(newRow[arrayTriggerKey], value, type, newRow)
  }

  setPendingDeletion = key => {
    const { pendingDeletion } = this.state
    this.setState({ pendingDeletion: { ...pendingDeletion, [key]: true } })
  }

  onRemoveRow = (key: string, index: number) => {
    const { pendingDeletion } = this.state
    const { arrayInput, onValidateNext } = this.props
    arrayInput.value.splice(index, 1)
    this.setState({ pendingDeletion: { ...pendingDeletion, [key]: false } })
    onValidateNext()
  }

  onChange = (value, input: IInputItem, arrayIndex: number) => {
    const { applicationUIStore, arrayInput, type, onValidateNext } = this.props
    applicationUIStore.set(input, value, type, arrayInput.value[arrayIndex])
    onValidateNext()
  }

  renderAddRow = () => {
    const { arrayTriggerKey, arrayTemplate } = this.props.arrayInput
    const input = arrayTemplate[arrayTriggerKey]
    const { apiParam, overviewValue, value, isMessage, isArray, ...rest } = input

    return (
      <div className={styles.part_wrapper}>
        <Input value={value || ''} onChange={value => this.onAddRow(value)} {...rest} />
      </div>
    )
  }

  onBlur = (input: IInputItem) => {
    const { applicationUIStore } = this.props
    applicationUIStore.validate(input, true)
  }

  renderInputRow = (
    parentKey: string,
    input: IInputItem,
    index: number,
    arrayIndex: number,
  ) => {
    const {
      apiParam,
      overviewValue,
      value,
      isMessage,
      isArray,
      ishidden,
      ...rest
    } = input

    return (
      <AnimatedHeight
        key={`${parentKey}_${index}`}
        className={[styles.child, 'flex_column'].join(' ')}
        visible={!input.ishidden}
        maxHeight={`600px`}
      >
        <Input
          value={value || ''}
          onChange={value => this.onChange(value, input, arrayIndex)}
          onBlur={() => this.onBlur(input)}
          {...rest}
        />
      </AnimatedHeight>
    )
  }

  renderInputSection = (inputState: IInputState, arrayIndex: number) => {
    const { pendingDeletion } = this.state
    const key = inputState[Object.keys(inputState)[0]].key as string
    return (
      <AnimatedHeight
        key={key}
        visible={!inputState.ishidden && !pendingDeletion[key]}
        onHidden={() => this.onRemoveRow(key, arrayIndex)}
        maxHeight={`600px`}
      >
        <div className={styles.part_wrapper}>
          <Button
            styleKey="button_application_secondary"
            className={styles.remove_button}
            onClick={() => this.setPendingDeletion(key)}
          >
            Ta bort
          </Button>
          {Object.keys(inputState).map((key, index) =>
            this.renderInputRow(key, inputState[key], index, arrayIndex),
          )}
        </div>
      </AnimatedHeight>
    )
  }

  render() {
    const { arrayInput } = this.props

    const values: IInputState[] = arrayInput.value

    return (
      <>
        {values.map(this.renderInputSection)}
        {this.renderAddRow()}
      </>
    )
  }
}
