import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore } from 'stores'
import { Button, Container } from '~components'
import { tx } from '~libs/i18n'
import { ResultInputApplicant } from './ResultInputApplicant'
import { InterestBar } from '../../components'
import styles from '../../../modal.module.scss'

export interface ResultInputScreenProps {
  applicationUIStore?: ApplicationUIStore
  storeInfoKey: string
  step: number
  maxStep: number
  noBack?: boolean
  sharedInfo?: boolean
  visible: boolean
  onHidden: () => void
}

@inject('applicationUIStore')
@observer
export class ResultInputScreen extends Component<ResultInputScreenProps> {
  containerRef = React.createRef<Container>()

  state = {
    isValid: false,
    screenIndex: 0,
  }

  constructor(props) {
    super(props)
    this.state = {
      isValid: props.applicationUIStore.validateAll(props.storeInfoKey, false),
      screenIndex: props.applicationUIStore.screenIndex,
    }
  }

  componentDidMount() {
    this.scrollToTop()
  }

  componentDidUpdate(lastProps) {
    const { applicationUIStore, storeInfoKey } = this.props
    if (this.state.screenIndex !== applicationUIStore.screenIndex) {
      this.setState({
        screenIndex: applicationUIStore.screenIndex,
        isValid: applicationUIStore.validateAll(storeInfoKey, false),
      })
      this.scrollToTop()
    }

    if (!this.props.visible && this.props.visible !== lastProps.visible) {
      this.props.onHidden()
    }
  }

  scrollToTop = () => {
    const container = findDOMNode(this.containerRef.current)
    container && (container.parentElement.scrollTop = 0)
  }

  onDone = () => {
    const { applicationUIStore, storeInfoKey } = this.props
    if (applicationUIStore.validateAll(storeInfoKey, true)) {
      applicationUIStore.nextScreen()
    }
  }

  onValidateNext = () => {
    const { applicationUIStore, storeInfoKey } = this.props
    const isValid = applicationUIStore.validateAll(storeInfoKey, false)
    if (isValid !== this.state.isValid) {
      this.setState({ isValid })
    }
  }

  render() {
    const {
      applicationUIStore,
      storeInfoKey,
      step,
      maxStep,
      noBack,
      sharedInfo,
    } = this.props

    const { isValid } = this.state

    return (
      <>
        <Container
          ref={this.containerRef}
          className={[styles.container, styles.result_input_screen].join(' ')}
          application
        >
          <p className={styles.strapline}>
            {tx('application.result_input.strapline', { step, maxStep })}
          </p>
          <ResultInputApplicant
            onValidateNext={this.onValidateNext}
            storeInfoKey={storeInfoKey}
            type={'applicant'}
            titleKey={
              sharedInfo && applicationUIStore.hasCoapplicant() ? 'title_co' : 'title'
            }
          />

          {!sharedInfo && applicationUIStore.hasCoapplicant() && (
            <ResultInputApplicant
              onValidateNext={this.onValidateNext}
              storeInfoKey={storeInfoKey}
              type={'coapplicant'}
            />
          )}

          <Button onClick={this.onDone} className={styles.button} disabled={!isValid}>
            {tx('misc.move_on')}
          </Button>
          {!noBack && (
            <Button
              onClick={applicationUIStore.previousScreen}
              styleKey="button_application_back"
              className={styles.back_button}
            >
              {tx('misc.back')}
            </Button>
          )}
        </Container>
        <InterestBar />
      </>
    )
  }
}
