import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { observer, inject } from 'mobx-react'
import { Container, Div } from '~components'
import { ApplicationUIStore } from 'stores'
import { InlineInput } from '../components'
import { getCurrentHeaderHeight, screenWidthMd } from '~libs/constants'
import { runAnimationFrame } from '~libs/utils'
import styles from '../../modal.module.scss'

export interface InputStepScreenProps {
  applicationUIStore?: ApplicationUIStore
  storeInfoKey: string
  visible: boolean
  onHidden: () => void
}

@inject('applicationUIStore')
@observer
export class InputStepScreen extends Component<InputStepScreenProps> {
  containerRef = React.createRef<Container>()
  componentDidMount() {
    const { applicationUIStore } = this.props
    setTimeout(() => {
      applicationUIStore.setInputStepIndex(0)
    }, 1)

    runAnimationFrame(() => this.forceScrollTop(0))
  }

  componentDidUpdate(lastProps) {
    runAnimationFrame(() => this.forceScrollTop(0))

    if (!this.props.visible && this.props.visible !== lastProps.visible) {
      this.props.onHidden()
    }
  }

  forceScrollTop = count => {
    const container = findDOMNode(this.containerRef.current) as HTMLElement
    container && (container.scrollTop = 0)
    if (count < 3) {
      runAnimationFrame(() => this.forceScrollTop(count + 1))
    }
  }

  render() {
    const { applicationUIStore, storeInfoKey } = this.props

    const storeInfo = applicationUIStore.getKeyInfo(storeInfoKey, 'applicant')
    const currentIndex = applicationUIStore.inputStepIndex

    const approxParagraphHeight = window.innerWidth <= screenWidthMd ? 60 : 100

    const topPosition = Math.max(
      window.innerHeight / 2 - approxParagraphHeight * (currentIndex + 1),
      getCurrentHeaderHeight(),
    )
    const containerHeight = approxParagraphHeight * currentIndex + 300
    return (
      <Div className={styles.input_step_screen}>
        <Container
          ref={this.containerRef}
          application
          className={styles.input_step_screen_container}
          style={{
            marginTop: `${topPosition}px`,
            height: `${containerHeight}px`,
          }}
        >
          <Div className={styles.content}>
            {storeInfo.storeKeys
              .map(k => applicationUIStore.get(k, 'applicant'))
              .map((input, index) => (
                <InlineInput
                  index={index}
                  input={input}
                  key={input.storepath.join('.')}
                />
              ))}
            <InlineInput index={storeInfo.storeKeys.length} key={'BUTTON'} button />
          </Div>
        </Container>
      </Div>
    )
  }
}
