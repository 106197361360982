import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { observer, inject } from 'mobx-react'
import { ApplicationQueueStore, ApplicationUIStore } from 'stores'
import { Header } from '~views/Header'
import {
  QueueScreen,
  InviteScreen,
  InvitedScreen,
  SorryScreen,
  GratitudeScreen,
} from './ApplicationQueueModal/screens'
import {
  IntroScreen,
  InputStepScreen,
  SuggestionScreen,
  ResultInputScreen,
  FinishScreen,
  OverviewScreen,
} from './ApplicationModal/screens'
import { AnimatedScreens } from './AnimatedScreens'
import modalStyles from './modal.module.scss'

export interface ModalProps {
  applicationQueueStore?: ApplicationQueueStore
  applicationUIStore?: ApplicationUIStore
}

type TStore = 'application' | 'queue'

interface ModalState {
  preventScrolling: boolean
  animatedIndex: number
  lastStore: TStore
}

interface IModalOptions {
  store?: ApplicationQueueStore | ApplicationUIStore
  renderFunc?: (options: IModalOptions) => JSX.Element[]
  background?: 'bg_eggshell' | 'bg_off-white'
  storeName?: TStore
}

@inject('applicationQueueStore', 'applicationUIStore')
@observer
export class Modal extends Component<ModalProps> {
  sectionRef = React.createRef<HTMLElement>()

  state: ModalState = {
    preventScrolling: false,
    animatedIndex: 0,
    lastStore: 'application',
  }

  timeout: NodeJS.Timer

  componentDidMount() {
    const section = findDOMNode(this.sectionRef.current)
    section.addEventListener('touchmove', this.preventScrolling, { passive: false })

    const options = this.getModalOptions()
    this.setState({ animatedIndex: options.store.screenIndex })
  }

  componentWillUnmount() {
    const section = findDOMNode(this.sectionRef.current)
    section.removeEventListener('touchmove', this.preventScrolling)
  }

  componentDidUpdate() {
    const { applicationQueueStore } = this.props
    const preventScrolling = applicationQueueStore.screenIndex === 1

    if (preventScrolling !== this.state.preventScrolling) {
      this.setState({ preventScrolling })
    }

    const options = this.getModalOptions()

    if (
      options.storeName !== this.state.lastStore ||
      (!options.store.visible && this.state.animatedIndex !== options.store.screenIndex)
    ) {
      this.setState({
        animatedIndex: options.store.screenIndex,
        lastStore: options.storeName,
      })
    }
  }

  preventScrolling = e => {
    this.state.preventScrolling && e.preventDefault()
    return false
  }

  renderQueueScreens = (options: IModalOptions) => {
    const { visible, screenIndex } = this.props.applicationQueueStore

    const comps: { Comp: any; props: any }[] = [
      {
        Comp: QueueScreen,
        props: {},
      },
      {
        Comp: InviteScreen,
        props: { afterSuccessfullInvitation: true },
      },
      {
        Comp: InviteScreen,
        props: { afterSuccessfullInvitation: false },
      },
      {
        Comp: InvitedScreen,
        props: {},
      },
      {
        Comp: GratitudeScreen,
        props: {},
      },
      {
        Comp: SorryScreen,
        props: {},
      },
    ]

    return comps.map((comp, index) => (
      <comp.Comp
        key={`queue_${index}`}
        visible={visible && screenIndex === index}
        onHidden={() => this.setState({ animatedIndex: options.store.screenIndex })}
        {...comp.props}
      />
    ))
  }

  renderApplicationScreens = (options: IModalOptions) => {
    const { visible, screenIndex } = this.props.applicationUIStore

    const comps: { Comp: any; props: any }[] = [
      {
        Comp: IntroScreen,
        props: {},
      },
      {
        Comp: InputStepScreen,
        props: { storeInfoKey: 'initialInfo' },
      },
      {
        Comp: SuggestionScreen,
        props: {},
      },
      {
        Comp: ResultInputScreen,
        props: {
          storeInfoKey: 'applicant',
          step: 2,
          maxStep: 5,
          noBack: true,
        },
      },
      {
        Comp: ResultInputScreen,
        props: {
          storeInfoKey: 'employment',
          step: 3,
          maxStep: 5,
        },
      },
      {
        Comp: ResultInputScreen,
        props: {
          storeInfoKey: 'collateral',
          step: 4,
          maxStep: 5,
          sharedInfo: true,
        },
      },
      {
        Comp: ResultInputScreen,
        props: {
          storeInfoKey: 'debt',
          step: 5,
          maxStep: 5,
        },
      },
      {
        Comp: OverviewScreen,
        props: {
          startIndex: 3,
        },
      },
      {
        Comp: FinishScreen,
        props: {},
      },
    ]

    return comps.map((comp, index) => (
      <comp.Comp
        key={`queue_${index}`}
        visible={visible && screenIndex === index}
        onHidden={() => this.setState({ animatedIndex: options.store.screenIndex })}
        {...comp.props}
      />
    ))
  }

  getModalOptions = () => {
    const options: IModalOptions = {}

    const { animatedIndex } = this.state
    const { applicationQueueStore, applicationUIStore } = this.props

    if (applicationQueueStore.visible) {
      options.store = applicationQueueStore
      options.renderFunc = this.renderQueueScreens
      options.background = [5].includes(animatedIndex) ? 'bg_eggshell' : 'bg_off-white'
      options.storeName = 'queue'
    } else {
      options.store = applicationUIStore
      options.renderFunc = this.renderApplicationScreens
      options.background = [2, 8].includes(animatedIndex) ? 'bg_eggshell' : 'bg_off-white'
      options.storeName = 'application'
    }

    return options
  }

  render() {
    const options = this.getModalOptions()

    const { visible, close } = options.store
    const { animatedIndex } = this.state

    const classes = [modalStyles.section, modalStyles.section, options.background]
    const headerClasses = [options.background, modalStyles.header]

    if (visible) {
      classes.push(modalStyles.visible)
    }

    return (
      <section ref={this.sectionRef} className={classes.join(' ')}>
        <Header className={headerClasses.join(' ')} modal onClose={close} />
        <AnimatedScreens screenIndex={animatedIndex}>
          {options.renderFunc(options)}
        </AnimatedScreens>
      </section>
    )
  }
}
