import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore } from 'stores'
import { Button, Input, InputProps } from '~components'
import styles from './styles.module.scss'
import { IInputItem } from '~stores/ApplicationUIStore/props'

export interface IInlineInput extends InputProps {
  applicationUIStore?: ApplicationUIStore
  index?: number
  button?: boolean
  input?: IInputItem
}

@inject('applicationUIStore')
@observer
export class InlineInput extends Component<IInlineInput> {
  static enterTimeout: NodeJS.Timer
  static clickTimeout: NodeJS.Timer

  onEnter = () => {
    if (InlineInput.enterTimeout) {
      return
    }
    InlineInput.enterTimeout = setTimeout(() => (InlineInput.enterTimeout = null), 400)

    const { applicationUIStore, input } = this.props
    if (applicationUIStore.validate(input, true)) {
      applicationUIStore.incrementInputStepIndex()
    }
  }

  onClick = () => {
    InlineInput.clickTimeout = setTimeout(() => (InlineInput.clickTimeout = null), 400)
    const { applicationUIStore, index } = this.props
    applicationUIStore.setInputStepIndex(index)
  }

  onBlur = () => {
    if (InlineInput.clickTimeout) {
      return
    }
    this.onEnter()
  }

  onChange = value => {
    const { applicationUIStore, input } = this.props
    applicationUIStore.set(input, value, 'applicant')

    if (input.type === 'select') {
      this.onEnter()
    }
  }

  renderButton = () => {
    const { applicationUIStore, index } = this.props
    const currentIndex = applicationUIStore.inputStepIndex
    const buttonClass = [styles.wrapper]

    if (index > currentIndex) {
      buttonClass.push(styles.hiddenInput)
    } else {
      buttonClass.push(styles.currentInput)
    }

    return (
      <div className={buttonClass.join(' ')}>
        <Button onClick={applicationUIStore.nextScreen} className={styles.button}>
          Gå vidare
        </Button>
      </div>
    )
  }

  renderInput = () => {
    const { applicationUIStore, index, input } = this.props
    const currentIndex = applicationUIStore.inputStepIndex

    const wrapperClass = [styles.wrapper]

    const inputWrapperClass = [styles.inputWrapper]
    const inputClass = [styles.input]
    const inputClassGroups = [inputWrapperClass, inputClass]

    if (index < currentIndex) {
      wrapperClass.push(styles.pastInput)
      inputClassGroups.map(e => {
        e.push('h2', styles.pastInputTextStyle)
      })
    } else if (index > currentIndex) {
      wrapperClass.push(styles.hiddenInput)
      inputClassGroups.map(e => {
        e.push('h1')
      })
    } else {
      wrapperClass.push(styles.currentInput)
      inputClassGroups.map(e => {
        e.push('h1')
      })
    }

    let result = (input.text || '')
      .split('{?}')
      .map((part, i) => <React.Fragment key={i.toString()}>{part}</React.Fragment>)

    const {
      apiParam,
      validator,
      error,
      label,
      overviewValue,
      isMessage,
      isArray,
      ishidden,
      arrayTemplate,
      arrayTriggerKey,
      ...rest
    } = input

    result.splice(
      1,
      0,
      <Input
        key={'input'}
        className={inputClass.join(' ')}
        widthMatchContent
        onChange={this.onChange}
        onEnter={this.onEnter}
        onBlur={this.onBlur}
        focus={index === currentIndex}
        {...rest}
        value={input.value || ''}
      />,
    )

    return (
      <div
        onMouseDown={index < currentIndex ? this.onClick : null}
        className={wrapperClass.join(' ')}
      >
        <span className={inputWrapperClass.join(' ')}>{result}</span>
        {!!error && <p className={styles.error}>{error}</p>}
      </div>
    )
  }

  render() {
    return this.props.button ? this.renderButton() : this.renderInput()
  }
}
