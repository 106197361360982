import { graphql } from 'gatsby'

export const imageFields = graphql`
  fragment imageFields on wordpress__wp_media {
    id
    caption
    localFile {
      childImageSharp {
        large: fluid(maxWidth: 2220) {
          ...GatsbyImageSharpFluid
          presentationWidth
          presentationHeight
        }
      }
    }
  }
`
