import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Container, Spinner, AnimatedBounce } from '~components'
import { ApplicationUIStore } from 'stores'
import { tx } from '~libs/i18n'
import styles from '../../modal.module.scss'

export interface SuggestionScreenProps {
  applicationUIStore?: ApplicationUIStore
  visible: boolean
  onHidden: () => void
}

@inject('applicationUIStore')
@observer
export class SuggestionScreen extends Component<SuggestionScreenProps> {
  state = { mounted: false }

  timeout?: NodeJS.Timer

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 1)
    this.timeout = setTimeout(this.nextPage, 4000)
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  nextPage = () => {
    const { applicationUIStore } = this.props
    applicationUIStore.nextScreen()
  }

  render() {
    const { visible, onHidden } = this.props
    return (
      <Container
        className={[styles.container, 'text-center', 'flex_fixed_center_col'].join(' ')}
        application
      >
        <AnimatedBounce
          delayMS={500}
          visible={visible && this.state.mounted}
          onHidden={onHidden}
        >
          <p className={styles.strapline}>{tx('application.suggestion.strapline')}</p>
          <h1>{tx('application.suggestion.title')}</h1>
          <Spinner color="green" className={styles.spinner} />
        </AnimatedBounce>
      </Container>
    )
  }
}
