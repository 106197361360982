import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Container, AnimatedBounce, Input } from '~components'
import { tx } from '~libs/i18n'
import { ApplicationQueueStore } from 'stores'
import styles from '../../modal.module.scss'

export interface GratitudeScreenProps {
  applicationQueueStore?: ApplicationQueueStore
  visible: boolean
  onHidden: () => void
}

@inject('applicationQueueStore')
@observer
export class GratitudeScreen extends Component<GratitudeScreenProps> {
  state = { mounted: false }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 1)
  }

  render() {
    const { applicationQueueStore, visible, onHidden } = this.props
    return (
      <Container
        className={[styles.container, styles.queue_wrapper, 'flex_center_col'].join(' ')}
        application
      >
        <AnimatedBounce visible={visible && this.state.mounted} onHidden={onHidden}>
          <h1>{tx('application_queue.gratitude_screen.title')}</h1>

          <Button
            className={styles.intro_button}
            onClick={applicationQueueStore.closeAndReset}
          >
            {tx('application_queue.gratitude_screen.back_button').toUpperCase()}
          </Button>
        </AnimatedBounce>
      </Container>
    )
  }
}
