import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Container, AnimatedBounce } from '~components'
import { tx } from '~libs/i18n'
import { ApplicationUIStore } from 'stores'
import styles from '../../modal.module.scss'

export interface IntroScreenProps {
  applicationUIStore?: ApplicationUIStore
  visible: boolean
  onHidden: () => void
}

@inject('applicationUIStore')
@observer
export class IntroScreen extends Component<IntroScreenProps> {
  state = { mounted: false }
  timeout: NodeJS.Timer

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ mounted: true }), 1)
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  render() {
    const { applicationUIStore, visible, onHidden } = this.props
    return (
      <Container
        className={[styles.container, styles.intro_wrapper, 'flex_center_col'].join(' ')}
        application
      >
        <AnimatedBounce
          delayMS={500}
          visible={visible && this.state.mounted}
          onHidden={onHidden}
        >
          <span className={styles.strapline}>{tx('application.intro.strapline')}</span>
          <h1>{tx('application.intro.title')}</h1>
          <Button className={styles.intro_button} onClick={applicationUIStore.nextScreen}>
            {tx('application.intro.button')}
          </Button>
        </AnimatedBounce>
      </Container>
    )
  }
}
