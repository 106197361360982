import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore, RfqStore } from 'stores'
import { Button, Checkbox, Input, Spinner } from '~components'
import { tx } from '~libs/i18n'
import styles from './styles.module.scss'
import { PidnFormatter } from '~libs/formatters'

type TypeApplicant = 'applicant' | 'coapplicant'
export interface BankIdProps {
  applicationUIStore?: ApplicationUIStore
  rfqStore?: RfqStore
}

export interface BankIdState {
  applicant: {
    accept_end_user_agreement: boolean
    sign_pidn: string
  }
  coapplicant: {
    accept_end_user_agreement: boolean
    sign_pidn: string
  }
  coapplicant_no_sign: boolean
  coapplicant_email: string
}

@inject('applicationUIStore', 'rfqStore')
@observer
export class BankId extends Component<BankIdProps, BankIdState> {
  constructor(props) {
    super(props)
    this.state = {
      applicant: {
        accept_end_user_agreement: false,
        sign_pidn: '',
      },
      coapplicant: {
        accept_end_user_agreement: false,
        sign_pidn: '',
      },
      coapplicant_no_sign: false,
      coapplicant_email: '',
    }
  }

  componentDidUpdate() {
    const { applicationUIStore, rfqStore } = this.props
    //const { coapplicant_no_sign } = this.state
    let expectedVerificationCount = 1
    let verificationCount = 0
    if (rfqStore.state['applicant'] === 'verifySuccess') {
      verificationCount++
    }

    /*
    if (applicationUIStore.hasCoapplicant()) {
      expectedVerificationCount++
      if (coapplicant_no_sign) {
        rfqStore.sentRfqSignatureLink && verificationCount++
      } else {
        rfqStore.state['coapplicant'] === 'verifySuccess' && verificationCount++
      }
    }
    */

    if (verificationCount === expectedVerificationCount) {
      applicationUIStore.nextScreen()
    }
  }

  onSign = (applicant: TypeApplicant) => {
    const { applicationUIStore, rfqStore } = this.props
    const { accept_end_user_agreement, sign_pidn } = this.state[applicant]

    const config = applicationUIStore.getConfig()

    rfqStore.createRfqSignature(
      applicant,
      config,
      PidnFormatter(sign_pidn, true),
      accept_end_user_agreement,
    )
  }

  onSendLink = () => {
    const { applicationUIStore, rfqStore } = this.props
    const { coapplicant_email } = this.state
    const config = applicationUIStore.getConfig()
    rfqStore.sendRfqSignatureLink(config, coapplicant_email)
  }

  sign_wrapper = (type: TypeApplicant) => {
    const { applicationUIStore, rfqStore } = this.props
    const { coapplicant_no_sign, coapplicant_email } = this.state
    const applicant = this.state[type]
    const { accept_end_user_agreement, sign_pidn } = applicant

    const nameItem = applicationUIStore.get('applicant.name', type)
    const pidnItem = applicationUIStore.get('applicant.pidn', type)

    if (['signingRfq', 'verifyingRfq'].includes(rfqStore.state[type])) {
      return (
        <div className={styles.sign_wrapper}>
          <h2 className="text-center">
            {tx('application.overview.sign_title_verifying')}
          </h2>
          <p className={styles.sign_info}>
            {tx('application.overview.sign_info_verifying')}
          </p>
          <Spinner color="yellow" className={styles.sign_spinner} />

          <Button
            onClick={() => rfqStore.cancelSignRfq(type)}
            styleKey="button_application_back"
            className={styles.back_button}
          >
            {tx('misc.cancel')}
          </Button>
        </div>
      )
    }

    if (['error'].includes(rfqStore.state[type])) {
      return (
        <div className={styles.sign_wrapper}>
          <h2 className="text-center">{tx('application.overview.sign_title_timeout')}</h2>
          <p className={styles.sign_info}>
            {tx('application.overview.sign_info_timeout')}
          </p>

          <Button
            disabled={!accept_end_user_agreement || pidnItem.value !== sign_pidn}
            className={[styles.sign_Button].join(' ')}
            onClick={() => this.onSign(type)}
          >
            {tx('application.overview.sign_button_timeout')}
          </Button>
        </div>
      )
    }

    if (['verifySuccess'].includes(rfqStore.state[type])) {
      return (
        <div className={styles.sign_wrapper}>
          <h2 className="text-center">{tx('application.overview.sign_title_success')}</h2>
          <p className={styles.sign_info}>
            {tx('application.overview.sign_info_success')}
          </p>
        </div>
      )
    }

    const title =
      type === 'coapplicant'
        ? 'application.overview.sign_title_coapplicant_secondary'
        : applicationUIStore.hasCoapplicant()
        ? 'application.overview.sign_title_coapplicant'
        : 'application.overview.sign_title'

    if (type === 'coapplicant' && coapplicant_no_sign) {
      return (
        <div className={styles.sign_wrapper}>
          <h2 className="text-center">{tx(title)}</h2>
          <p className={styles.sign_info}>{tx('application.overview.sign_info_link')}</p>

          <div className={styles.sign_pidn_wrapper}>
            <Input
              value={coapplicant_email}
              onChange={value => this.setState({ coapplicant_email: value })}
              label={tx('application.models.applicant.email')}
              type="email"
            />
          </div>

          <Button
            disabled={!coapplicant_email}
            className={[styles.sign_Button].join(' ')}
            onClick={this.onSendLink}
          >
            {tx('application.overview.sign_button_link')}
          </Button>

          <div className={styles.agreement_wrapper}>
            <Checkbox
              selected={coapplicant_no_sign}
              onChange={value =>
                this.setState({
                  coapplicant_no_sign: value,
                })
              }
            />
            <p>{tx('application.overview.sign_coapplicant_no_sign')}</p>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.sign_wrapper}>
        <h2 className="text-center">{tx(title)}</h2>
        {type === 'applicant' && (
          <p className={styles.sign_info}>{tx('application.overview.sign_info')}</p>
        )}

        <div className={styles.sign_pidn_wrapper}>
          <h4 className={styles.sign_name}>{nameItem.value || '-'}</h4>
          <Input
            value={sign_pidn}
            onChange={value =>
              this.setState({ [type]: { ...applicant, sign_pidn: value } } as any)
            }
            placeholder="19851005-0356"
            label={tx('application.models.applicant.pidn')}
            formatter={PidnFormatter}
            type="tel"
            maxLength={13}
          />
        </div>

        <Button
          disabled={!accept_end_user_agreement || pidnItem.value !== sign_pidn}
          className={[styles.sign_Button].join(' ')}
          onClick={() => this.onSign(type)}
        >
          {tx('application.overview.sign_button')}
        </Button>

        {type === 'coapplicant' && (
          <div className={styles.agreement_wrapper}>
            <Checkbox
              selected={coapplicant_no_sign}
              onChange={value =>
                this.setState({
                  coapplicant_no_sign: value,
                })
              }
            />
            <p>{tx('application.overview.sign_coapplicant_no_sign')}</p>
          </div>
        )}

        <div className={styles.agreement_wrapper}>
          <Checkbox
            selected={accept_end_user_agreement}
            onChange={value =>
              this.setState({
                [type]: { ...applicant, accept_end_user_agreement: value },
              } as any)
            }
          />
          <p>{tx('application.models.applicant.accept_end_user_agreement')}</p>
        </div>
      </div>
    )
  }

  render() {
    const { applicationUIStore } = this.props

    return (
      <>
        {this.sign_wrapper('applicant')}
        {/*applicationUIStore.hasCoapplicant() && this.sign_wrapper('coapplicant')} */}

        <Button
          onClick={applicationUIStore.previousScreen}
          styleKey="button_application_back"
          className={styles.back_button}
        >
          {tx('misc.back')}
        </Button>
      </>
    )
  }
}
