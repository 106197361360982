import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore } from 'stores'
import styles from './styles.module.scss'
import { tx } from '~libs/i18n'
import { Container, Col, Row } from '~components'

export interface InterestBarProps {
  applicationUIStore?: ApplicationUIStore
}

@inject('applicationUIStore')
@observer
export class InterestBar extends Component<InterestBarProps> {
  render() {
    return (
      <div className={styles.wrapper}>
        <Container className={styles.container} application>
          <Row className={'w-100'}>
            <Col xs={12} md={6} lg={6}>
              <Row>
                <Col xs={'auto'} className={styles.noPaddingRight}>
                  <span className={['body-regular', styles.title].join(' ')}>
                    {tx('application.interest_bar.your_new_cost')}{' '}
                  </span>
                  <span className={'label-big d-inline d-md-none'}>0,79% </span>
                  <p className={'h3 d-none d-md-block'}>0,79% </p>
                </Col>
                <Col xs={['auto', styles.noPaddingLeft].join(' ')}>
                  <span className={['label', styles.interestSave].join(' ')}>
                    -0,85%{' '}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <span className={styles.title}>
                {tx('application.interest_bar.you_will_save')}{' '}
              </span>
              <span className={'label-big d-inline d-md-none'}>
                16 000 {tx('misc.currency_per_year')}
              </span>
              <p className={'h3 d-none d-md-block text-nowrap'}>
                16 000 {tx('misc.currency_per_year')}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
