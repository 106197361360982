import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore, typeApplicants } from 'stores'
import { Input } from '~components'
import styles from './styles.module.scss'
import { IInputItem } from '~stores/ApplicationUIStore/props'

export interface ResultInputProps {
  applicationUIStore?: ApplicationUIStore
  type: typeApplicants
  input: IInputItem
  previous?: IInputItem
  next?: IInputItem
  className?: string
  onValidateNext: () => void
}

@inject('applicationUIStore')
@observer
export class ResultInput extends Component<ResultInputProps> {
  onChange = (value: any) => {
    const { applicationUIStore, input, type, onValidateNext } = this.props
    applicationUIStore.set(input, value, type)
    if (input.type === 'select') {
      applicationUIStore.validate(input, true)
    }
    onValidateNext()
  }

  onEnter = elem => {
    const { applicationUIStore, input } = this.props
    if (applicationUIStore.validate(input, true)) {
      // Focus next input

      const isChild = typeof input.ishidden !== 'undefined'
      let next
      if (isChild && !elem.nextSibling) {
        next = this.findNextFocusableFromParent(elem.parentElement.parentElement)
      } else {
        next = this.findNextFocusable(elem.nextSibling, isChild)
      }
      next && next.focus()
    }
  }

  onBlur = () => {
    const { applicationUIStore, input } = this.props
    applicationUIStore.validate(input, true)
  }

  findNextFocusable = (nextElem: HTMLElement, iterateParent?: boolean) => {
    while (nextElem !== null) {
      switch (nextElem.tagName) {
        case 'INPUT':
        case 'SELECT':
          return nextElem
        case 'DIV':
          const recursiveElem = this.findNextFocusable(nextElem.firstChild as HTMLElement)
          if (recursiveElem) {
            return recursiveElem
          }
      }

      if (iterateParent && nextElem.nextSibling === null) {
        return this.findNextFocusableFromParent(nextElem.parentElement.parentElement)
      }
      nextElem = nextElem.nextSibling as HTMLElement
    }
    return nextElem
  }

  findNextFocusableFromParent = (currentElem: HTMLElement) => {
    const parent = currentElem.parentElement
    const children = Array.from(parent.children)
    const indexOfCurrent = children.indexOf(currentElem)
    if (children.length > indexOfCurrent + 1) {
      return this.findNextFocusable(children[indexOfCurrent + 1] as HTMLElement)
    } else {
      return null
    }
  }

  render() {
    const { previous, next, input, type, className } = this.props
    const {
      label,
      label_secondary,
      apiParam,
      overviewValue,
      value,
      isMessage,
      ishidden,
      isArray,
      arrayTemplate,
      arrayTriggerKey,
      ...rest
    } = input

    const isChild = typeof input.ishidden !== 'undefined'

    if (isChild) {
      const isFirst =
        !previous || typeof previous.ishidden === 'undefined' || previous.ishidden
      const isLast = !next || typeof next.ishidden === 'undefined' || next.ishidden
      const childClasses = [styles.input_child]
      if (isFirst) {
        childClasses.push(styles.input_child_first)
      }
      if (isLast) {
        childClasses.push(styles.input_child_last)
      }
      if (isMessage) {
        childClasses.push(styles.message)
        if (value === 'Ja') {
          childClasses.push('arrow_yes')
        } else {
          childClasses.push('arrow_no')
        }
      }
      if (className) {
        childClasses.push(className)
      }

      return (
        <div className={childClasses.join(' ')}>
          {isMessage && input.text}
          {!isMessage && (
            <Input
              label={type === 'coapplicant' ? label_secondary || label : label}
              value={value || ''}
              onChange={value => this.onChange(value)}
              onBlur={this.onBlur}
              onEnter={this.onEnter}
              {...rest}
            />
          )}
        </div>
      )
    }
    return (
      <Input
        label={type === 'coapplicant' ? label_secondary || label : label}
        value={value || ''}
        onChange={value => this.onChange(value)}
        onBlur={this.onBlur}
        onEnter={this.onEnter}
        {...rest}
      />
    )
  }
}
