import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore, typeApplicants } from 'stores'
import { Row, Col, Button } from '~components'
import { tx } from '~libs/i18n'
import styles from './styles.module.scss'
import { IInputItem } from '~stores/ApplicationUIStore/props'

export interface OverviewPartialProps {
  applicationUIStore?: ApplicationUIStore
  storeInfoKey: string
  editIndex: number
  type: typeApplicants
}

@inject('applicationUIStore')
@observer
export class OverviewPartial extends Component<OverviewPartialProps> {
  renderArray = (item: IInputItem) => {
    return (
      <Col xs={12} key={item.storepath.join('.')}>
        {item.value
          .map((children, index) => {
            return (
              <Row key={item.storepath.join('.') + index} className={styles.children}>
                {Object.keys(children)
                  .filter(key => !children[key].isMessage)
                  .map(key => {
                    const child: IInputItem = children[key]
                    if (!child.storepath) {
                      child.storepath = [key, index.toString]
                    }
                    return this.renderRow(child)
                  })}
              </Row>
            )
          })
          .flat()}
      </Col>
    )
  }

  renderRow = (item: IInputItem) => {
    const { type } = this.props
    let value = item.value || '-'
    if (item.type === 'select') {
      const selected = item.selectableValues.find(v => v.value === value)
      value = selected ? selected.name : '-'
    }

    if (item.overviewValue) {
      value = item.overviewValue.replace('{value}', value)
    }

    return (
      <React.Fragment key={item.storepath.join('.')}>
        <Col xs={6}>
          <p className={styles.label}>
            {type === 'coapplicant' ? item.label_secondary || item.label : item.label}
          </p>
        </Col>
        <Col xs={6}>
          <p className={styles.reply}>{value}</p>
        </Col>
      </React.Fragment>
    )
  }

  render() {
    const { editIndex, storeInfoKey, applicationUIStore, type } = this.props

    const info = applicationUIStore.getKeyInfo(storeInfoKey, type)

    if (!info || !info.title) {
      return null
    }

    return (
      <Row className={styles.wrapper}>
        <div className={styles.border} />
        <Col xs={12} className={styles.header}>
          <h4>{info.title}</h4>
          <Button
            styleKey="button_application_secondary"
            onClick={() => applicationUIStore.goToScreen(editIndex)}
          >
            {tx('misc.edit').toLowerCase()}
          </Button>
        </Col>
        {info.storeKeys
          .map(key => applicationUIStore.get(key, type))
          .filter(item => !item.ishidden && !item.isMessage)
          .map(item => (item.isArray ? this.renderArray(item) : this.renderRow(item)))}
      </Row>
    )
  }
}
