import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Modal } from '~modals'
import { Sections, Header, Footer } from '~views'
import { FaqTemplate } from '~views/wp_templates/FaqTemplate'
import { ISections, IHeadSection, INavigation, IActionButton } from '~models'
import { withStore } from '~libs/mobx'
import '~libs/graphql'
import { Seo } from '~views/shared/components'

interface PageTemplateProps {
  data: {
    wordpressPage: {
      id: number
      acf: {
        template: 'Page Builder' | 'FAQ'
        sections_page: ISections
        header: IHeadSection
        header_buttons: IActionButton[]
        seo: {
          title: string
          description: string
          keywords: string
        }
      }
    }
    wordpressAcfOptions: {
      site: {
        facebook: string
        instagram: string
        twitter: string
      }
      navigation: INavigation
    }
  }
}

class PageTemplate extends Component<PageTemplateProps> {
  renderTemplate() {
    const { wordpressPage } = this.props.data
    switch (wordpressPage.acf.template) {
      case 'FAQ':
        return <FaqTemplate wordpressPage={wordpressPage} />
    }
    return <Sections sections={wordpressPage.acf.sections_page} />
  }

  render() {
    const { wordpressAcfOptions, wordpressPage } = this.props.data
    const { seo, header_buttons } = wordpressPage.acf
    return (
      <>
        <Seo {...seo} />
        <Header
          links={wordpressAcfOptions.navigation.headerlinks}
          buttons={header_buttons || wordpressAcfOptions.navigation.header_buttons}
        />
        {this.renderTemplate()}
        <Modal />
        <Footer
          options={wordpressAcfOptions.site}
          links={wordpressAcfOptions.navigation.footerlinks}
        />
      </>
    )
  }
}

export default withStore(PageTemplate)

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      acf {
        template
        sections_page {
          ...pageFields
        }
        header {
          ...pageHeadsectionFields
        }
        header_buttons {
          button_title
          button_title_xs
          type
        }
        seo {
          description
          keywords
        }
      }
    }
    wordpressAcfOptions {
      site {
        facebook
        instagram
        twitter
      }
      navigation {
        headerlinks {
          title
          link {
            post_name
          }
        }
        header_buttons {
          button_title
          button_title_xs
          type
        }
        footerlinks {
          heading
          links {
            title
            link {
              post_name
            }
          }
        }
      }
    }
  }
`
