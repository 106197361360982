import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { HeadSection } from '~views/shared/sections'
import { Container, Col, Div, Row } from '~components'
import styles from './styles.module.scss'

export interface TemplateProps {
  wordpressPage: any
  allWordpressWpFaq: any
}

export class Template extends Component<TemplateProps> {
  render() {
    const { header } = this.props.wordpressPage.acf
    const { edges } = this.props.allWordpressWpFaq

    return (
      <>
        <HeadSection section={header} />
        <Div className={styles.faq}>
          {edges.map(function(item, index) {
            return (
              <section key={item.node.id}>
                {index !== 0 && <hr className={styles.line} />}
                <Container main className={styles.faqContainer}>
                  <Row className="d-flex align-items-baseline">
                    <Col md={6} lg={5} xl={4}>
                      <h2 className={styles.heading}>{item.node.acf.heading} </h2>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block" />
                    <Col md={6} xl={7}>
                      {item.node.acf.paragraphs.map(function(p) {
                        return (
                          <Div key={`${item.node.id} ${p.question}`}>
                            <h3 className={styles.question}>{p.question}</h3>
                            <p className={styles.answer}>{p.answer}</p>
                          </Div>
                        )
                      })}
                    </Col>
                  </Row>
                </Container>
              </section>
            )
          })}
        </Div>
      </>
    )
  }
}

const query = graphql`
  {
    allWordpressWpFaq {
      edges {
        node {
          id
          acf {
            heading
            paragraphs {
              question
              answer
            }
          }
        }
      }
    }
  }
`

export interface FaqTemplateProps {
  wordpressPage: any
}

export const FaqTemplate = (props: FaqTemplateProps) => (
  <StaticQuery
    query={query}
    render={data => (
      <Template
        wordpressPage={props.wordpressPage}
        allWordpressWpFaq={data.allWordpressWpFaq}
      />
    )}
  />
)
