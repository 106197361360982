import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Container, AnimatedBounce, Input } from '~components'
import { tx } from '~libs/i18n'
import { ApplicationQueueStore } from 'stores'
import styles from '../../modal.module.scss'
import { Icons } from '~libs/assets'

export interface InviteScreenProps {
  applicationQueueStore?: ApplicationQueueStore
  afterSuccessfullInvitation: boolean
  visible: boolean
  onHidden: () => void
}

@inject('applicationQueueStore')
@observer
export class InviteScreen extends Component<InviteScreenProps> {
  state = { mounted: false }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 1)
  }

  onBlur = item => {
    const { applicationQueueStore } = this.props
    applicationQueueStore.validateItem(item, true)
  }
  onEnter = item => {
    const { applicationQueueStore } = this.props
    if (
      applicationQueueStore.validateItem(item, true) &&
      applicationQueueStore.statuses.requestQueue !== 'pending'
    ) {
      applicationQueueStore.requestQueue()
    }
  }

  renderInputs() {
    const { applicationQueueStore } = this.props

    return applicationQueueStore.invitations.map((input, index) => (
      <Input
        key={`invite_${index}`}
        {...input}
        onChange={value => (input.value = value)}
        onBlur={() => this.onBlur(input)}
        onEnter={() => this.onEnter(input)}
      />
    ))
  }

  render() {
    const {
      afterSuccessfullInvitation,
      applicationQueueStore,
      visible,
      onHidden,
    } = this.props

    const txPrefix = afterSuccessfullInvitation
      ? 'application_queue.invite_screen_2'
      : 'application_queue.invite_screen'

    return (
      <Container
        className={[styles.container, styles.invite_wrapper, 'flex_center_col'].join(' ')}
        application
      >
        <AnimatedBounce visible={visible && this.state.mounted} onHidden={onHidden}>
          <span className={styles.strapline}>{tx(`${txPrefix}.strapline`)}</span>
          <h1>{tx(`${txPrefix}.title`)}</h1>
          <p className="text">{tx(`${txPrefix}.text`)}</p>

          {this.renderInputs()}

          <button
            className={styles.add_button}
            onClick={applicationQueueStore.addInvitationEmail}
          >
            <img src={Icons.plus} />
            {tx(`${txPrefix}.add_button`)}
          </button>

          <Button
            className={styles.intro_button}
            onClick={applicationQueueStore.sendInvitations}
            disabled={
              !applicationQueueStore.validateItems(
                applicationQueueStore.invitations,
                false,
              ) && applicationQueueStore.statuses.sendInvitations !== 'pending'
            }
          >
            {tx('misc.send')}
          </Button>

          <Button
            className={`${styles.text_button} ${styles.back_button}`}
            styleKey="text_button"
            onClick={applicationQueueStore.closeAndReset}
          >
            {tx(`${txPrefix}.back_button`).toUpperCase()}
          </Button>
        </AnimatedBounce>
      </Container>
    )
  }
}
