import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore, typeApplicants } from 'stores'
import { AnimatedHeight } from '~components'
import { ResultInput, ResultInputArray } from '../../components'

export interface ResultInputApplicantProps {
  applicationUIStore?: ApplicationUIStore
  storeInfoKey: string
  type: typeApplicants
  titleKey?: string
  onValidateNext: () => void
}

@inject('applicationUIStore')
@observer
export class ResultInputApplicant extends Component<ResultInputApplicantProps> {
  render() {
    const {
      applicationUIStore,
      storeInfoKey,
      type,
      titleKey,
      onValidateNext,
    } = this.props

    const info = applicationUIStore.getKeyInfo(storeInfoKey, type)

    const inputItems = info.storeKeys.map(storeKey => {
      return applicationUIStore.get(storeKey, type)
    })

    let previous = null
    const inputs = inputItems.map((input, i) => {
      let next = null
      for (let index = i + 1; index < inputItems.length; index++) {
        if (!inputItems[i + 1].ishidden) {
          next = inputItems[i + 1]
        }
      }

      let result
      let maxHeight = '600px'
      let animated = typeof input.ishidden !== 'undefined'
      if (input.isArray) {
        maxHeight = `${400 + 600 * input.value.length}px`
        result = (
          <ResultInputArray
            onValidateNext={onValidateNext}
            arrayInput={input}
            type={type}
          />
        )
        animated = true
      } else {
        result = (
          <ResultInput
            onValidateNext={onValidateNext}
            input={input}
            previous={previous}
            next={next}
            type={type}
          />
        )
      }

      if (!input.ishidden) {
        previous = input
      }

      if (animated) {
        return (
          <AnimatedHeight
            className={'flex_column'}
            key={type + input.storepath.join('.')}
            visible={!input.ishidden}
            maxHeight={maxHeight}
          >
            {result}
          </AnimatedHeight>
        )
      }

      return (
        <React.Fragment key={type + input.storepath.join('.')}>{result}</React.Fragment>
      )
    })
    return (
      <>
        <h2 className="text-center">{info[titleKey || 'title']}</h2>
        {inputs}
      </>
    )
  }
}
