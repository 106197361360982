import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Container, AnimatedBounce } from '~components'
import { ApplicationUIStore, RfqStore } from 'stores'
import { tx } from '~libs/i18n'
import styles from '../../modal.module.scss'

export interface FinishScreenProps {
  applicationUIStore?: ApplicationUIStore
  rfqStore?: RfqStore
  visible: boolean
  onHidden: () => void
}

@inject('applicationUIStore', 'rfqStore')
@observer
export class FinishScreen extends Component<FinishScreenProps> {
  state = { mounted: false }
  componentDidMount() {
    const { applicationUIStore, rfqStore } = this.props
    applicationUIStore.reset()
    rfqStore.reset()
    setTimeout(() => this.setState({ mounted: true }), 1)
  }

  render() {
    const { applicationUIStore, visible, onHidden } = this.props
    return (
      <Container
        className={[styles.container, styles.intro_wrapper, 'flex_center_col'].join(' ')}
        application
      >
        <AnimatedBounce
          delayMS={500}
          visible={visible && this.state.mounted}
          onHidden={onHidden}
        >
          <span className={styles.strapline}>{tx('application.finish.strapline')}</span>
          <h1>{tx('application.finish.title')}</h1>
          <Button className={styles.intro_button} onClick={applicationUIStore.close}>
            {tx('application.finish.button')}
          </Button>
        </AnimatedBounce>
      </Container>
    )
  }
}
