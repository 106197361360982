import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Container, AnimatedBounce, Input } from '~components'
import { tx } from '~libs/i18n'
import { ApplicationQueueStore } from 'stores'
import styles from '../../modal.module.scss'

export interface SorryScreenProps {
  applicationQueueStore?: ApplicationQueueStore
  visible: boolean
  onHidden: () => void
}

@inject('applicationQueueStore')
@observer
export class SorryScreen extends Component<SorryScreenProps> {
  state = { mounted: false }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 1)
  }

  onBlur = item => {
    const { applicationQueueStore } = this.props
    applicationQueueStore.validateItem(item, true)
  }
  onEnter = item => {
    const { applicationQueueStore } = this.props
    if (
      applicationQueueStore.validateItem(item, true) &&
      applicationQueueStore.statuses.requestInvitedQueue !== 'pending'
    ) {
      applicationQueueStore.requestInvitedQueue()
    }
  }

  render() {
    const { applicationQueueStore, visible, onHidden } = this.props
    const emailField = applicationQueueStore.fields.email
    const mobileField = applicationQueueStore.fields.mobile
    return (
      <Container
        className={[styles.container, styles.queue_wrapper, 'flex_center_col'].join(' ')}
        application
      >
        <AnimatedBounce visible={visible && this.state.mounted} onHidden={onHidden}>
          <span className={styles.strapline}>
            {tx('application_queue.sorry_screen.strapline')}
          </span>
          <h1>{tx('application_queue.sorry_screen.title')}</h1>
          <p className="text">{tx('application_queue.sorry_screen.text')}</p>

          <Input
            {...emailField}
            onChange={value => (emailField.value = value)}
            onBlur={() => this.onBlur(emailField)}
            onEnter={() => this.onEnter(emailField)}
          />

          <Input
            {...mobileField}
            onChange={value => (mobileField.value = value)}
            onBlur={() => this.onBlur(mobileField)}
            onEnter={() => this.onEnter(mobileField)}
          />

          <Button
            className={styles.intro_button}
            onClick={applicationQueueStore.requestInvitedQueue}
            disabled={
              !applicationQueueStore.validateItems([emailField, mobileField], false) &&
              applicationQueueStore.statuses.requestInvitedQueue !== 'pending'
            }
          >
            {tx('misc.ok')}
          </Button>
        </AnimatedBounce>
      </Container>
    )
  }
}
