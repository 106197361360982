import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ApplicationUIStore } from 'stores'
import styles from './styles.module.scss'

export interface AnimatedScreensProps {
  applicationUIStore?: ApplicationUIStore
  children?: React.ReactNode[]
  screenIndex: number
}

@inject('applicationUIStore')
@observer
export class AnimatedScreens extends Component<AnimatedScreensProps> {
  render() {
    const { children, screenIndex } = this.props
    return children[screenIndex]
    /*return children.map((child, index) => {
      return (
        <div
          className={`${styles.wrapper} ${index !== screenIndex ? styles.hidden : ''}`}
        >
          {child}
        </div>
      )
    })*/
  }
}
